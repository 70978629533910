import React, { useEffect, useState } from "react";
import config from "../../../Config";
import Slider from "react-slick";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import trofe from "../../../assets/images/partner/trofe-img.png";
import iphone15_banner from "../../../assets/images/packages_banner/iphone15_banner.png";
import { ToastContainer, toast } from "react-toastify";
import PackagePurchased from "./PackagePurchase";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { SparklesIcon, TableCellsIcon, ChatBubbleBottomCenterTextIcon, ArrowLongLeftIcon, LinkIcon } from "@heroicons/react/24/outline";
import { Doughnut } from 'react-chartjs-2';
import { NumberFormat } from "../../Dashboard/General/Functions";
import LoginCheck from "../General/LoginCheck";


function PackagesDetails(props) {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    const [Name, setName] = useState('');
    const [Amount, setAmount] = useState('');
    const [ROI, setROI] = useState('');
    const [PackageType, setPackageType] = useState('');
    const [NoofDays, setNoofDays] = useState('');
    const [TagLine, setTagLine] = useState('');
    const [DirectBonus, setDirectBonus] = useState('');
    const [DailyProfitReturn, setDailyProfitReturn] = useState('');
    const [MonthlyProfit, setMonthlyProfit] = useState('');
    const [TotalProfitReturn, setTotalProfitReturn] = useState('');
    const [ProductDelivery, setProductDelivery] = useState('');
    const [slider, setSlider] = useState([]);
    const [SelectActive, setSelectActive] = useState('Producd');
    const [Investment, setInvestment] = useState('');

    const [Profit, setProfit] = useState('');



    const [PackageData, setPackageData] = useState({});

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let params = useParams();

    //Selected Package Amount
    const [SPA, setSPA] = useState(0);

    const [MaxDuration, setMaxDuration] = useState(16);

    //Per Month Percentage
    const [PMP, setPMP] = useState(0);
    const [Duration, setDuration] = useState(1);
    const [Progress, setProgress] = useState(0);
    const [Percentage, setPercentage] = useState(0);


    useEffect(() => {

        let TRP = 0;

        if (SPA == 100000) {
            setMaxDuration(24);
            setPercentage(8);
        }
        else if (SPA == 200000) {
            setPercentage(9);
            setMaxDuration(24);
        }
        else if (SPA == 500000) {
            setPercentage(10);
            setMaxDuration(24);
        }
        else if (SPA == 1000000) {
            setPercentage(10);
            setMaxDuration(24);
        }
        else if (SPA == 3000000) {
            setPercentage(11);
            setMaxDuration(24);
        }
        else if (SPA == 5000000) {
            setPercentage(11);
            setMaxDuration(24);
        }
        else {
            setPercentage(12);
            setMaxDuration(24);
        }

        TRP = (SPA / 100 * Percentage) * MaxDuration;


        let progress = ((SPA / 100 * Percentage) * Duration) / TRP * 100;

        let PMP1 = TRP / MaxDuration * Duration;
        setPMP(PMP1);

        //Bar Percentage =  (Package amount / 100 * 13 * NoofMonthSelected in Bar)  / TRP * 100;

        setProfit(TRP);
        setProgress(progress);

        // console.log(progress);
        //  console.log(TRP);

    }, [SPA, Duration, MaxDuration]);



    useEffect(() => {
        setDuration(1);
    }, [SPA]);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Member_Type: MemberType,
            ID: params.id,
        };
        // console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Package/GetPackagebyID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log("GetPackagebyID", response.data)

                if (response.data.status_code == 1) {
                    setPackageData(response.data.Package);
                    setSlider(response.data.SliderImages);
                    setName(response.data.Package.Name);
                    setAmount(response.data.Package.Amount);
                    setInvestment(response.data.Package.Amount);
                    setROI(response.data.Package.ROI);
                    setPackageType(response.data.Package.PackageType);
                    setNoofDays(response.data.Package.NoofDays);
                    setTagLine(response.data.Package.TagLine);
                    setDirectBonus(response.data.Package.DirectBonus);
                    setDailyProfitReturn(response.data.Package.DailyProfitReturn);
                    setMonthlyProfit(response.data.Package.MonthlyProfit);
                    setTotalProfitReturn(response.data.Package.TotalProfitReturn);
                    setProductDelivery(response.data.Package.ProductDelivery);
                    setSPA(response.data.Package.Amount);
                    setLoading(false);

                } else if (response.data.status_message === "Invalid Access Key.") {
                    localStorage.clear();
                    navigate("/login");
                }

                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {
            });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        autoplaySpeed: 2000,
        centerPadding: '0px',
        pauseOnHover: false,
        arrows: false,
    };


    // const handleChangeInvestment = (e) => {
    //     console.log(e.target.value);
    //     setDuration(e.target.Duration.value);
    //     setInvestment(e.target.Investment.value);
    //     setProfit(e.target.Profit.value);
    // }


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'PAYMENT STATISTICS'
            }
        },
    };

    

    const handleDuration = (e) => {
        // console.log(e.target.value);
        setDuration(e.target.value);

    }


    return (
        <>
            <LoginCheck />
            <ToastContainer />
            <PackagePurchased show={show} handleClose={handleClose} loading={loading} PackageData={PackageData} />
            <div className="package-detail-section">
                <div className="row mb-3 align-items-center">
                    <div className="col-md-6">
                        <div className="section-title ">Packages Details</div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <Link to={`/partner/packages`} className='button button-bs button-icon-bs'><ArrowLongLeftIcon /><span>Back</span></Link>
                    </div>
                </div>

                <div className="row gy-3 mt-3">
                    <div className="col-md-8">
                        <Slider {...settings} className="slider-dots">
                            {slider.map((item, index) => (
                                <div className="package-image" key={index}>
                                    <img src={`${config.root}Images/Packages/${item}`} alt={`Image ${index + 1}`} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className="col-md-4">
                        <div className="properte-card">
                            <div className="ribbon">
                                <span className="ribbon-text">{Name} Package</span>
                                <div className="ribbon-corner"></div>
                            </div>
                            <div className="content dash-bg-card">

                                <div className="package-header">
                                    <h4>{TagLine}</h4>
                                </div>
                                <ul className="list">
                                    <li><label htmlFor="">Direct Bonus:</label> <span>{DirectBonus}</span></li>
                                    <li><label htmlFor="">Daily profit Return:</label> <span>{DailyProfitReturn}</span></li>
                                    <li><label htmlFor="">Monthly Profit:</label> <span>{MonthlyProfit}</span></li>
                                    <li><label htmlFor="">Total Profit Return:</label> <span>{TotalProfitReturn}</span></li>
                                    <li><label htmlFor="">Product deliver:</label> <span>{ProductDelivery}</span></li>
                                    <li><label htmlFor="">Days:</label> <span>{NoofDays}</span></li>
                                </ul>
                                <ul className="princing">
                                    <li><label htmlFor="">Product</label><span>{TagLine}</span></li>
                                    <li><label htmlFor="">Price</label><span>{(Amount)} PKR</span></li>
                                </ul>
                                <div className="package-footer">
                                    <button className="button button-bs w-100" onClick={handleShow}>
                                        Buy Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gy-3 mt-3">
                    <div className="col-md-12">
                        <div className="dash-bg-card">
                            <div className="row align-items-end">
                                <div className="col-md-7">
                                    <img src={trofe} alt="" className="img-fluid" />
                                </div>
                                <div className="col-md-5">

                                    <ul className="opportunity-icon-list">
                                        <h1>Earning Opportunities</h1>
                                        <span>There are 9 ways to earn with our program.</span>
                                        <li>
                                            <CheckCircleIcon />
                                            <p>Direct Bonus</p>
                                        </li>
                                        <li>
                                            <CheckCircleIcon />
                                            <p>Referral Bonus</p>
                                        </li>
                                        <li>
                                            <CheckCircleIcon />
                                            <p>Profit Return</p>
                                        </li>
                                        <li>
                                            <CheckCircleIcon />
                                            <p>Leadership Bonus</p>
                                        </li>
                                        <li>
                                            <CheckCircleIcon />
                                            <p>Royalty Income</p>
                                        </li>
                                        <li>
                                            <CheckCircleIcon />
                                            <p>Rewards Income</p>
                                        </li>
                                        <li>
                                            <CheckCircleIcon />
                                            <p>Pool Reward</p>
                                        </li>
                                        <li>
                                            <CheckCircleIcon />
                                            <p>Promotions</p>
                                        </li>
                                        <li>
                                            <CheckCircleIcon />
                                            <p>Mag Club Members</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                <div className="dash-bg-card mt-5">
                    <h4 className="calc-title">Profit Calculator</h4>
                    <div className="row align-items-center">
                        <div className="col-md-5">

                            <div className="calc-item">
                                <div className="d-flex justify-content-between">
                                    <p>Package Value (PKR)</p>
                                    <p><strong>{SPA}</strong></p>
                                </div>
                                <ul className="range-list">
                                    <li>
                                        <button className={SPA == "100000" ? "active" : ""} onClick={(e) => setSPA(100000)}></button>
                                    </li>
                                    <li>
                                        <button className={SPA == "200000" ? "active" : ""} onClick={(e) => setSPA(200000)}></button>
                                    </li>
                                    <li>
                                        <button className={SPA == "500000" ? "active" : ""} onClick={(e) => setSPA(500000)}></button>
                                    </li>
                                    <li>
                                        <button className={SPA == "1000000" ? "active" : ""} onClick={(e) => setSPA(1000000)}></button>
                                    </li>
                                    <li>
                                        <button className={SPA == "3000000" ? "active" : ""} onClick={(e) => setSPA(3000000)}></button>
                                    </li>
                                    <li>
                                        <button className={SPA == "10000000" ? "active" : ""} onClick={(e) => setSPA(10000000)}></button>
                                    </li>
                                </ul>


                            </div>
                            <div className="calc-item">
                                <div className="d-flex justify-content-between">
                                    <p>Duration (Month)</p>
                                    <p><strong>MAX Months {MaxDuration}</strong></p>
                                </div>
                                <input
                                    type="range"
                                    value={Duration}
                                    min="1"
                                    style={{ width: "100%" }}
                                    max={MaxDuration} onChange={(e) => handleDuration(e)} />
                            </div>
                            <div className="calc-item">
                                <div className="d-flex justify-content-between">
                                    <p>Total Profit (PKR)</p>
                                    <p><strong>{PMP}</strong></p>
                                </div>
                                <div className="calc-progress">
                                    <div style={{ width: Progress + "%" }}></div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-7">
                            <div className="calc-result">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <Doughnut
                                            type="doughnut" // This line specifies the chart type as 'bar'
                                            data={{
                                                labels: ['Investment', 'Profit'],
                                                datasets: [{
                                                    label: false,
                                                    data: [SPA, PMP],
                                                    backgroundColor: [
                                                        'rgb(54, 162, 235)',
                                                        'rgb(255, 21, 0)',

                                                    ],
                                                    hoverOffset: 4
                                                }]
                                            }}
                                            options={options}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="calc-detail-list">
                                            <li>
                                                <span>Investment (PKR)</span>
                                                <strong>{NumberFormat(Investment)}</strong>
                                            </li>
                                            <li>
                                                <span>Duration (Month)</span>
                                                <strong>{(Duration)}</strong>
                                            </li>
                                            <li>
                                                <span>Profit Calculated (PKR)</span>
                                                <strong>{NumberFormat(PMP)}</strong>
                                            </li>
                                            <li>
                                                <span>Total Profit Return (PKR)</span>
                                                <strong>{NumberFormat(Profit)}</strong>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 pb-5">
                    <div className="col-md-12">
                        <div className="active-link-menu">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className={`nav-link ${SelectActive == "Producd" ? "active" : ""}`} onClick={(e) => setSelectActive("Producd", e)} href="#">
                                        <LinkIcon />
                                        <span>Producd Detail</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="product-feature">
                            <ul>
                                {params.id === "2" && (
                                    <li>
                                        <a
                                            target="_blank"
                                            href="https://www.apple.com/iphone-15/"
                                            className="product-info"
                                        >
                                            Click here to view complete specification of the Product you are purchasing
                                        </a>
                                    </li>
                                )}
                                {params.id === "3" && (
                                    <li>
                                        <a
                                            target="_blank"
                                            href="https://www.apple.com/iphone-15-pro/specs/"
                                            className="product-info"
                                        >
                                            Click here to view complete specification of the Product you are purchasing
                                        </a>
                                    </li>
                                )}
                                {params.id === "4" && (
                                    <li>
                                        <a
                                            target="_blank"
                                            href="https://www.apple.com/iphone-15-pro/specs/"
                                            className="product-info"
                                        >
                                            Click here to view complete specification of the Product you are purchasing
                                        </a>
                                    </li>
                                )}
                                {(params.id === "1" || params.id === "5" || params.id === "6" || params.id === "7" || params.id === "10") && (
                                    <li>
                                        <a
                                            target="_blank"
                                            href="https://tdap.gov.pk/wp-content/uploads/2024/02/Meat.pdf"
                                            className="product-info"
                                        >
                                            Click here to view complete scope of the Product you are trading
                                        </a>
                                    </li>
                                )}




                            </ul>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default PackagesDetails; 